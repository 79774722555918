import styles from './index.module.scss';

export default function Map() {
    return (
        <div className={styles.map}>
            <div className={styles.wrap}>
                <div className={styles.col_stats}>
                    <div className={styles.stat}>
                        <h2>50</h2>
                        <div className={styles.label}>
                            <p>active</p>
                            <p>states</p>
                        </div>
                    </div>
                    <div className={styles.stat}>
                        <h2>30K</h2>
                        <div className={styles.label}>
                            <p>active</p>
                            <p>screens</p>
                        </div>
                    </div>
                    <div className={styles.stat}>
                        <h2>200M</h2>
                        <div className={styles.label}>
                            <p>monthly</p>
                            <p>views</p>
                        </div>
                    </div>
                </div>
                <div className={styles.col_video}>
                    <video autoPlay loop playsInline muted>
                        <source src="/videos/US-COVERAGE-0003.mp4" />
                    </video>
                </div>
            </div>
        </div>
    )
}